import * as React from 'react';
interface HeroImageProps {
	imgSrc: string;
}
const HeroImage: React.FC<HeroImageProps> = (props: HeroImageProps) => {
	return (
		<section
			className={'min-h-[330px] bg-cover bg-top md:min-h-[530px]'}
			style={{ backgroundImage: `url('${props.imgSrc}')` }}
		/>
	);
};

export default HeroImage;
