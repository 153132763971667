import './Map.css';
import * as React from 'react';
// @ts-ignore
import { USMap } from 'career-page-components';
interface mapProps {
	data: any;
}

const CTA: React.FC<mapProps> = (props: mapProps) => {
	const mapOptions = {
		fill: '#02814E',
		onHoverFill: '#00954C',
		disabledFill: '#0A5738'
	};
	return (
		<section className={'bg-gp-light-green py-14'}>
			<div className={'map container mx-auto px-5 text-center'}>
				<h2 className={'font-lato text-3xl font-bold text-body'}>
					Our Locations
				</h2>
				<p className={'mt-6 mb-10 font-lato text-xl text-body'}>
					Click on your state below to view our current available
					opportunities.
				</p>
				<USMap
					searchPath={'/jobs/'}
					options={mapOptions}
					data={props.data}
				/>
			</div>
		</section>
	);
};

export default CTA;
