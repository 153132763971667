import * as React from 'react';

import img1 from '../../images/innovative-icon.svg';
import img2 from '../../images/top-notch.svg';
import img3 from '../../images/diversity.svg';

const Values: React.FC = () => {
	return (
		<section>
			<div className={'container mx-auto py-6 px-5 text-center'}>
				<h2 className={'font-lato text-3xl font-bold text-body'}>
					Our Values
				</h2>
			</div>
			<div className={'flex flex-col sm:flex-row'}>
				<div
					className={
						'flex flex-col items-center justify-center bg-[#0A5738] max-sm:min-h-[300px] sm:aspect-square sm:max-h-[420px] sm:w-1/3'
					}
				>
					<img
						className={'max-lg:max-h-[80px] max-lg:max-w-[80px]'}
						src={img1}
						alt={'Innovation'}
					/>
					<span
						className={
							'mt-8 h-[40px] max-w-xs text-center font-lato text-lg font-bold text-white md:mt-16 md:text-2xl'
						}
					>
						Innovative
					</span>
				</div>
				<div
					className={
						'flex flex-col items-center justify-center bg-[#00954C] max-sm:min-h-[300px] sm:aspect-square	sm:max-h-[420px] sm:w-1/3'
					}
				>
					<img
						className={'max-lg:max-h-[80px] max-lg:max-w-[80px]'}
						src={img2}
						alt={'Top-Notch'}
					/>
					<span
						className={
							'mt-8 h-[40px] max-w-xs text-center font-lato text-lg font-bold text-white md:mt-16 md:text-2xl'
						}
					>
						Delivering Top-Notch Customer Service
					</span>
				</div>
				<div
					className={
						'flex flex-col items-center justify-center bg-[#629580] max-sm:min-h-[300px] sm:aspect-square sm:max-h-[420px] sm:w-1/3'
					}
				>
					<img
						className={'max-lg:max-h-[80px] max-lg:max-w-[80px]'}
						src={img3}
						alt={'Diversity'}
					/>
					<span
						className={
							'mt-8 h-[40px] max-w-xs text-center font-lato text-lg font-bold text-white md:mt-16 md:text-2xl'
						}
					>
						Product Diversity
					</span>
				</div>
			</div>
		</section>
	);
};

export default Values;
