import * as React from 'react';

import operations from '../../images/operations.png';
import hr from '../../images/hr.png';
import engineering from '../../images/engineering.png';
import sales from '../../images/sales.png';
import customerService from '../../images/customer-service.png';
import finance from '../../images/finance.png';
import logistics from '../../images/logistics.png';
import quality from '../../images/quality.png';
import rd from '../../images/r&d.png';
import dev from '../../images/dev.png';

const Opportunities: React.FC = () => {
	return (
		<section className={'pb-6 pt-16'}>
			<div
				className={'container mx-auto flex flex-col items-center px-5'}
			>
				<h2
					className={'pb-8 font-lato text-[40px] font-bold text-body'}
				>
					Careers Area
				</h2>
				<div
					className={
						'mb-14 flex flex-row flex-wrap justify-center gap-4 gap-y-8'
					}
				>
					<div className={'flex flex-col items-center'}>
						<img width={260} src={operations} alt={'Operations'} />
						<span
							className={
								'font-lato text-xl font-bold text-gp-green'
							}
						>
							Operations
						</span>
					</div>

					<div className={'flex flex-col items-center'}>
						<img width={260} src={hr} alt={'Human Resources'} />
						<span
							className={
								'font-lato text-xl font-bold text-gp-green'
							}
						>
							Human Resources
						</span>
					</div>

					<div className={'flex flex-col items-center'}>
						<img
							width={260}
							src={engineering}
							alt={'Engineering'}
						/>
						<span
							className={
								'font-lato text-xl font-bold text-gp-green'
							}
						>
							Engineering
						</span>
					</div>
					<div className={'flex flex-col items-center'}>
						<img width={260} src={sales} alt={'Sales'} />
						<span
							className={
								'font-lato text-xl font-bold text-gp-green'
							}
						>
							Sales
						</span>
					</div>
					<div className={'flex flex-col items-center'}>
						<img width={260} src={finance} alt={'Finance'} />
						<span
							className={
								'font-lato text-xl font-bold text-gp-green'
							}
						>
							Finance
						</span>
					</div>
					<div className={'flex flex-col items-center'}>
						<img
							width={260}
							src={customerService}
							alt={'Customer Service'}
						/>
						<span
							className={
								'font-lato text-xl font-bold text-gp-green'
							}
						>
							Customer Service
						</span>
					</div>
					<div className={'flex flex-col items-center'}>
						<img width={260} src={logistics} alt={'Logistics'} />{' '}
						<span
							className={
								'font-lato text-xl font-bold text-gp-green'
							}
						>
							Logistics
						</span>
					</div>
					<div className={'flex flex-col items-center'}>
						<img
							width={260}
							src={dev}
							alt={'Product Development'}
						/>
						<span
							className={
								'font-lato text-xl font-bold text-gp-green'
							}
						>
							Product Development
						</span>
					</div>
					<div className={'flex flex-col items-center'}>
						<img
							width={260}
							src={rd}
							alt={'Research & Development'}
						/>
						<span
							className={
								'font-lato text-xl font-bold text-gp-green'
							}
						>
							Research & Development
						</span>
					</div>
					<div className={'flex flex-col items-center'}>
						<img width={260} src={quality} alt={'Quality'} />
						<span
							className={
								'font-lato text-xl font-bold text-gp-green'
							}
						>
							Quality
						</span>
					</div>
				</div>
				<a
					href={'/jobs/'}
					className={
						'mb-20 rounded bg-gp-green py-2 px-10 font-lato text-lg font-bold text-white hover:text-white hover:opacity-90 focus:opacity-90'
					}
				>
					View All Opportunities
				</a>
			</div>
		</section>
	);
};

export default Opportunities;
