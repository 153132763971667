import React from 'react';
import Layout from '../layouts/Layout';
import JobAlerts from '../components/JobAlerts/JobAlerts';
import Map from '../components/Map/Map';
import CTA from '../components/CTA/CTA';
import { graphql, useStaticQuery } from 'gatsby';
import Opportunities from '../components/Opportunities/Opportunities';
import OurMission from '../components/OurMission/OurMission';
import Values from '../components/Values/Values';
import WhoWeAre from '../components/WhoWeAre/WhoWeAre';
import TextSection from '../components/TextSection/TextSection';
import HeroImage from '../components/HeroImage/HeroImage';
import IndexHeroImg from '../images/index-hero.png';
import JobSearchForm from '../components/JobSearchForm/JobSearchForm';
const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'About Us',
			href: '/',
			active: false
		},
		{
			text: 'Careers',
			href: '/jobs/',
			active: false
		},
		{
			text: 'Meet the Team',
			href: '/team/',
			active: false
		},
		{
			text: 'Working Here',
			href: '/work-here/',
			active: false
		}
	];
	return (
		<Layout
			menuItems={menuItems}
			title={'Genpak Careers'}
			desc={'Genpak Careers'}
		>
			<HeroImage imgSrc={IndexHeroImg} />
			<JobSearchForm
				data={data}
				p={'Join our team and discover great new job opportunities!'}
			/>
			<TextSection
				h2={'Company History'}
				p={
					'Genpak is leading food packaging manufacturer and innovator. For over 50 years, Genpak has delivered high-quality, versatile food packaging solutions to companies across North America.'
				}
			/>
			<WhoWeAre />
			<Values />
			<OurMission />
			<Opportunities />
			<Map data={data} />
			<CTA
				h2={'Ready to get started?'}
				p={
					'Genpak is growing and wants you to join our successful team today!'
				}
			/>
			<JobAlerts />
		</Layout>
	);
};

export default IndexPage;
