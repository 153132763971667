import * as React from 'react';

import ourMission from '../../images/our-mission.png';

const OurMission: React.FC = () => {
	return (
		<section
			style={{ backgroundImage: `url('${ourMission}')` }}
			className={
				'flex min-h-[450px] w-full flex-col items-center justify-center bg-cover bg-center lg:min-h-[760px]'
			}
		>
			<div
				className={
					'container mx-auto flex h-full flex-row items-center px-5 max-md:justify-center'
				}
			>
				<div
					className={
						'min-w-[370px] max-w-[370px] bg-gp-green/[0.9] p-12 text-center'
					}
				>
					<h2
						className={
							'pb-6 font-lato text-3xl font-bold text-white'
						}
					>
						Our Mission
					</h2>
					<p className={'font-lato text-white '}>
						Advancing innovation packaging to enhance the human
						experience is more than just our Purpose Statement. It
						is our driving passion to make peoples’ lives better
						through the food packaging they use in their daily
						lives.
					</p>
				</div>
			</div>
		</section>
	);
};

export default OurMission;
