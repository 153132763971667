import * as React from 'react';
// @ts-ignore
import { HeroSearch } from 'career-page-components';
import './JobSearchForm.css';

interface JobSearchFormProps {
	data: any;
	h1?: string;
	p?: string;
}

const JobSearchForm: React.FC<JobSearchFormProps> = (
	props: JobSearchFormProps
) => {
	return (
		<section
			className={
				'section-job-search-form hero-form flex flex-col bg-gp-green py-4 px-3 font-lato max-md:pb-6'
			}
		>
			<h2
				className={`container mx-auto pt-5 ${
					props.p ? 'pb-2' : 'pb-6'
				} text-[32px] text-white max-md:text-center`}
			>
				{props.h1 ? (
					props.h1
				) : (
					<>
						Join the Pak! <i>#RewardingCareer</i>
					</>
				)}
			</h2>
			{props.p ? (
				<span
					className={
						'container mx-auto pb-5 text-xl text-white max-md:text-center'
					}
				>
					{props.p}
				</span>
			) : null}

			<HeroSearch
				data={props.data}
				options={{
					submitButtonText: 'Search',
					searchPath: '/jobs/'
				}}
				apiKey={'AIzaSyCHih204QmoSKcJhHZ2xCTWokZ2cKl1RXU'}
			/>
		</section>
	);
};

export default JobSearchForm;
