import * as React from 'react';

const WhoWeAre: React.FC = () => {
	return (
		<section>
			<div className={'container mx-auto py-6 px-5 text-center'}>
				<h2 className={'font-lato text-3xl font-bold text-body'}>
					Who We Are
				</h2>
				<iframe
					className={
						'mx-auto aspect-video w-full max-w-[780px] pt-10'
					}
					src={'https://www.youtube.com/embed/oquFUoERkVs'}
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowFullScreen
				/>
			</div>
		</section>
	);
};

export default WhoWeAre;
