import * as React from 'react';

interface TextSectionProps {
	h2: string;
	p: string;
}

const TextSection: React.FC<TextSectionProps> = (props: TextSectionProps) => {
	return (
		<section>
			<div className={'container mx-auto py-8 px-5 text-center'}>
				<h2 className={'font-lato text-3xl font-bold text-body'}>
					{props.h2}
				</h2>
				<p className={'py-6 font-lato text-xl text-body'}>{props.p}</p>
			</div>
		</section>
	);
};

export default TextSection;
